@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/luiss-sans?styles=29070,29069,29071,29073,29072,29068&display=swap");
@layer base {
  * {
    box-sizing: border-box;
    font-family: "Luiss Sans", sans-serif;
  }

  html,
  body
   {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
  }
  ol {
    list-style: unset;
    padding-left: 1rem;
  }

  #tos-container ol {
    counter-reset: item;
  }
  #tos-container li {
    display: table;
    counter-increment: item;
  }
  #tos-container li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
  }
  #tos-container li ol li {
    margin-left: -1rem;
  }
  #tos-container li ol li p {
    margin-left: 0.5rem;
  }
  #tos-container ol li h4 {margin-left: 1.2rem;}

  #tos-container ol li h4 {margin-left: 0.5rem;}
  @media screen and (max-width: 1860px) and (min-height: 640px) and (max-height: 900px) {
    #enter-btn-div, #privacy-link {
      margin-top: 0.5rem;
    }
    #welcome-title, #welcome-subtitle {
      padding-bottom: 0.5rem;
    }
  }
    
  
}